@tailwind base;
@tailwind components;
@tailwind utilities;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap');

@layer components {
  .my-react-select-container .my-react-select__control {
    @apply rounded-btn p-1 border-base-content/20 hover:border-base-content/20 shadow-none;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-base-content/20 hover:border-base-content/20 !outline !outline-2 !outline-offset-2 !outline-base-content/20;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-gray-100 border-2 border-gray-300;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-gray-600 bg-gray-100 hover:bg-gray-200
  }
}

body {
  font-family: 'Karla', sans-serif !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.css-105f2w0 {
  border: 6px solid var(--ccl-color-background-success, #4900FF) !important;
}

.css-vie39m {
  border: 4px solid var(--ccl-color-background-success, #4900FF) !important;
}

.css-tq1s2d,
.css-615rsu {
  border: 6px solid var(--ccl-color-background-success, #4900FF) !important;
  background-color: #4900FF !important;
}

#gsr-progress {
  float: right;
  margin-right: 12px;

  > span {
    display: inline-block;
    vertical-align: super;
    font-size: 0.7em;
  }
}

#copy-info {
  position: fixed;
  right: 0.75rem;
  bottom: 0.75rem;

  > button {
    color: #E5E6E6;
  }
}
